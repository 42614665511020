<template>

    <div class="h-full w-full bg-danger"></div>

</template>

<script>

    import { state } from '@/store';

    export default {
        components: {
        },
        data() {
            return {
                
            }
        },
        computed: {
            
        },
        mounted() {
            
        }
    }
</script>